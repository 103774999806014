import React from 'react'
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import ContentBanner from '../../../components/Common/ContentBanner'
import Footer from "../../../components/App/Footer"
import ExampleDetail from '../../../components/Examples/ExampleDetail'
import Helmet from 'react-helmet';
import ProjectStartArea from '../../../components/Common/StartProject';
import SEO from '../../../components/App/SEO';
import exampleMetaImage from '../../../assets/images/examples/widgets/twitter-follow-retweet.png';
import Constants from '../../../constatns'
import PlatformExample from '../../../components/Examples/PlarformExample';

// Functional component
const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="Twitterフォローいいねキャンペーン"
		  desc="このサンプルはTwitterでフォロー&いいねキャンペーンを行う方法です。タイムラインに残らないいいねは、リツイートより応募ハードルが低く拡散力が高く、フォロワーを多く獲得することができます。フォロワーからビジネスを知ってもらうきっかけを作り、エンゲージメント高めていくことに適しています。"
		  banner={exampleMetaImage}
		  pathname={'/examples/twitter/follow-like'}
		  type={'Article'}
		  shortName="Twitterのフォローいいねキャンペーン"
		  createdAt="2021-11-27"
		/>
	  <Navbar />
	  <ContentBanner
		pageTitle="Twitterフォローいいねキャンペーン"
		homePageText="Home"
		homePageUrl="/"
		activePageText="Twitterフォローいいねキャンペーン"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		},{
		  url: '/examples/twitter',
		  name: 'Twitterキャンペーン'
		}]}
	  />
	  <ExampleDetail
		title={"Twitterフォロー&いいねキャンペーン"}
		description={<>このサンプルはTwitterでフォロー&いいねキャンペーンを行う方法です。タイムラインに残らないいいねは、リツイートより応募ハードルが低く拡散力が高く、最近人気のあるキャンペーン方法です。<br/>フォロワーからビジネスを知ってもらうきっかけを作り、エンゲージメント高めていくことに適しています。</>}
		effects={[Constants.CAMPAIGN_EFFECTS.GET_EMAILS,Constants.CAMPAIGN_EFFECTS.GET_SNS_ACCOUNTS,Constants.CAMPAIGN_EFFECTS.GET_FOLLOWERS
		  , Constants.CAMPAIGN_EFFECTS.GET_HIGH_SPREAD, Constants.CAMPAIGN_EFFECTS.VERIFIED_BADGE, Constants.CAMPAIGN_EFFECTS.GAIN_RECOGNITION]}
		plan={'ライトプラン以上'}
		promotionId="690a48cd9f7c0d3865"
		platformExample={
		  <PlatformExample
			title="その他のTwitterキャンペーン"
			types={['twitterFollowRetweet', 'twitterFollowLike', 'twitterFollowHashtag','twitterToLine','twitterInstantWin','twitterMultipleAccounts']}
		  />
		}
	  />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples